.box{
    position: relative;
    background-color: black;
    padding-top: 3px;
}
.box .text{
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 25%; /* Adjust this value to move the positioned div up and down */
    text-align: left;
    width: 60%; /* Set the width of the positioned div */
    color: aliceblue;
    text-shadow: 2px 2px rgb(153, 153, 153);
}