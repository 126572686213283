body {
    margin: 0;
}

a {
    /* color: black; */
    text-decoration: none;
}

a:hover {
    color: #8cdb2b;
}